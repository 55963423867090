import React, { createContext, useEffect, useState } from "react";
import {
  getCustomerMe,
  getMyHourPacks,
  getMyServices,
  getUserMe,
  loginRequest,
  signInCustomerRequest
} from "../api/requests";

export const UserContext = createContext();

const REACT_APP_STORAGE_USER_TOKEN = process.env.REACT_APP_STORAGE_USER_TOKEN;

export default ({ children }) => {
  const initialValue = localStorage.getItem(REACT_APP_STORAGE_USER_TOKEN) ? undefined : null;
  const [user, setUser] = useState(initialValue);

  useEffect(() => {
    if (user === undefined) refreshUser()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshUser = () => {
    Promise.all([getUserMe(), getCustomerMe()])
      .then((result) => {
        const theUser = result[0].data;
        const theCust = result[1].data;
        setUser({ ...theUser, customer: theCust })
      }).catch(logout)
  };

  const refreshCustomer = () => {
    getCustomerMe()
      .then(({ data }) => {
        setUser((actualUser) => ({ ...actualUser, customer: data }))
      })
  };

  const login = (body) => {
    return loginRequest(body)
      .then(({ data }) => {
        if(data.user.customer){
          setUser(data.user);
          localStorage.setItem(REACT_APP_STORAGE_USER_TOKEN, data.jwt);
          refreshCustomer();
          return data.user;
        } else return false;
      })
  };

  const refreshContracts = () => {
    Promise.all([getMyHourPacks(), getMyServices()])
      .then((result) => {
        const hours_pack_contracts = result[0].data;
        const service_contracts = result[1].data;
        setUser((actualUser) => ({
          ...actualUser,
          customer: {
            ...(actualUser.customer || {}),
            hours_pack_contracts,
            service_contracts
          }
        }))
      })
  };

  const signIn = (body) => signInCustomerRequest(body).then(({ data }) => data);

  const logout = () => {
    localStorage.removeItem(REACT_APP_STORAGE_USER_TOKEN);
    setUser(null);
  };

  return <UserContext.Provider value={{ user, login, logout, refreshUser, signIn, refreshContracts, refreshCustomer }}
                               children={children}/>;
}
