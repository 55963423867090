import React, { createContext, useContext } from "react";
import { Container, Row } from "reactstrap";
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import TitleAndContent from "../components/shared/TitleAndContent";
import BookingSummary from "./BookingSummary";
import BookingDates from "./BookingDates";
import BookingCatered from "./BookingCatered";
import { BookingContext } from "../wrappers/BookingWrapper";
import useCheckUserBooking from "../components/hooks/useCheckUserBooking";

export const CheckUserBookingContext = createContext();

export default ({ match }) => {
  const checkUserBooking = useCheckUserBooking({});
  const { needsPriorVisit, isRefusedEmployment, isHourPackUnselected, noExistsUser, noExistsService } = checkUserBooking;
  const history = useHistory();
  const { booking } = useContext(BookingContext);
  const { service, hoursPack } = booking;


  const redirectToPriorVisit = (!noExistsService && !noExistsUser && needsPriorVisit && service.priorVisit);
  const accessToBooking = !noExistsUser && !noExistsService && (service.employments || []).length > 0;


  if (isHourPackUnselected || isRefusedEmployment) {
    history.goBack();
    return null;
  }

  return (
    <CheckUserBookingContext.Provider value={checkUserBooking}>
      <Container fluid={true}>
        <Title serviceName={service.name + (hoursPack ? ` (Pack ${hoursPack.hours} h.) ` : "")}/>
        <Row>
          <Switch>
            {redirectToPriorVisit && <Redirect to={"/service/" + service.priorVisit.url}/>}
            {accessToBooking && <Route exact path={"/booking/catered"} component={BookingCatered}/>}
            {accessToBooking && <Route exact path={"/booking/dates"} component={BookingDates}/>}
            {accessToBooking && <Route exact path={"/booking/summary"} component={BookingSummary}/>}
            {accessToBooking && <Redirect to={"/booking"}/>}
            {noExistsUser && <Redirect to={{ pathname: "/login", search: ("?returnTo=" + match.path) }}/>}
            <Redirect to={"/"}/>
          </Switch>
        </Row>
      </Container>
    </CheckUserBookingContext.Provider>
  )
}


const Title = ({ serviceName }) => {
  return (
    <Row className="justify-content-center bg-primary text-white pt-4">
      <TitleAndContent title={"RESERVA TU " + serviceName.toUpperCase()} tagTitle="h1"/>
    </Row>
  )
};
