import React from "react";
import AwesomeSlider from "react-awesome-slider"
import withAutoplay from "react-awesome-slider/dist/autoplay"
import banner1 from "../../../media/img/walls/banner1.jpg"
import banner2 from "../../../media/img/walls/banner2.jpg"
import "./slider.css"

const AutoplaySlider = withAutoplay(AwesomeSlider);

export default () => {
  return (
    <AutoplaySlider play={true} cancelOnInteraction={false} interval={10000} className="slider" bullets={false}
                    fillParent={false} style={{ height: "100%" }}>
      <div data-src={banner1}>
      </div>
      <div data-src={banner2}>
      </div>
    </AutoplaySlider>
  )
}
