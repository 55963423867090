import React from 'react';
import { Col, Container, Row } from "reactstrap";
import catalina from "../media/img/team/catalina.jpg";
import francisca from "../media/img/team/francisca.jpg";
import franciscaFisio from "../media/img/team/francisca-fisio.jpg";
import TitleAndContent from "../components/shared/TitleAndContent";


const team = [
  {
    name: "Catalina Moragues Pujol",
    img: catalina,
    description: [
      "Mi nombre es Cati Moragues Pujol, tengo 38 años, soy madre de dos niños y docente por convicción, decisión y vocación.",
      "Recuerdo mis juegos de infancia alrededor de una mesa acompañada de cuadernos, bolígrafos, y papeles que me ayudaban a recrear mi deseo de jugar a ser profesora. Con el tiempo, no sólo no cambié de opinión sino que busqué trazar mi propio camino en el apasionante mundo de la educación y la enseñanza.",
      "Unifiqué mi deseo de convertirme en docente con mi pasión por la redacción y cursé la licenciatura de Filología Hispánica (UIB, 1999-2004) Y mientras eso sucedía, trabajé como monitora de tiempo libre infantil y juvenil (curso realizado el año 2000 a través de la Cruz Roja Española) en la escuela de verano de Muro durante 6 temporadas (1999-2004).",
      "El año 2004 cursé el Máster de Formación Pedagógica y Didáctica de la lengua para la Enseñanza Secundaria Obligatoria y sólo un año después, el 2005 pasé a trabajar como profesora interina para la Conselleria de Educación. Mi vinculación laboral con dicho cuerpo sigue hoy vigente dada mi condición de funcionaria de carrera desde el 2007.",
      "En el 2015 me convertí en madre por primera vez y si bien mi interés y pasión por la enseñanza no variaron un ápice, sí me planteé en ese momento la validez e idoneidad de ciertas convenciones del sistema educativo vigente.  Esa inquietud me llevó a explorar nuevas vías y posibilidades metodológicas fundamentadas todas ellas en la puesta en valor del niño y su entorno como puntos de partida esenciales de cualquier experiencia educativa enriquecedora. Sólo un niño motivado, fortalecido por su entorno más cercano y como eje vertebrador de todo proceso de enseñanza puede alcanzar sus propias metas, aquellas que parten de sus propias necesidades y no de las impuestas por un sistema educativo cada día más globalizado en el que a veces cuesta integrar todas las sensibilidades existentes.",
      "El nacimiento de mi segundo hijo el año 2017 supuso el punto de inflexión necesario para terminar de convencerme de que, en demasiadas ocasiones, la excesiva burocratización del sistema, convierte en inaccesibles servicios que en la práctica son y deberían ser esenciales.",
      "El peregrinaje de un padre cuyo hijo es de necesidades educativas especiales es a veces agotador y si bien es cierto que suplimos este cansancio con nuestra fortaleza, en ocasiones, agradeceríamos la simplificación de trámites y la unificación de criterios de todos los profesionales que trabajan con él para su avance y beneficio. En este sentido, nace nuestro servicio Healthcare, un gabinete multidisciplinar que ofrece intervenciones a domicilio de fisioterapia, logopedia, psicología y terapia ocupacional. Intervenciones conjuntas, consensuadas, unificando criterios y simplificando procesos. En casa, para tranquilidad de los padres y de los niños. Unos niños cuyo cansancio, dada la vorágine de sesiones a las que deben asistir semanalmente,  provoca, a veces, la falta de adherencia al propio tratamiento.",
      "Este ambicioso proyecto aglutina cabeza y corazón y, sin duda alguna, éste late al son también de aquellos que luchamos, día a día, por los derechos de quienes necesitan otras alas para poder volar.",
      "Queremos ser capaces de llegar a todos los perfiles, a todas las necesidades y ofrecer un servicio integral, personalizado y vertebrador del ocio, la formación y los menesteres específicos claves para el óptimo desarrollo de cada niño.",
      "Buscad en TODONANNIES vuestra pieza clave, aquella que encaje y dé a vuestro puzle la armonía deseada. Y si no la encontráis, hablemos, estamos aquí para diseñarla."
    ]
  },
  {
    name: "Francisca Serra Pons",
    img: francisca,
    description: [
      "Mi nombre es Xisca Serra Pons, tengo 33 años y mi vocación por el cuidado de los niños nace en mí ya desde muy pronto con mi condición de hermana mayor de una chica y un chico. ",
      "Con el paso del tiempo, descubrí que no sólo estaba dispuesta a cuidar incondicionalmente de ellos sino que quería convertir esa pasión en mi modo de vida. Fue entonces cuando formalicé mis estudios en dicho campo y como resultado de ello soy hoy titulada en el CFGS de Educación Infantil (2008-2010) y poseedora del Grado Universitario de Ed. Infantil (2011-2014), además de Monitora de actividades de tiempo libre Infantil y Juvenil. (2016)",
      "Mi experiencia personal y laboral se ha centrado en la educación de los niños en su primera infancia (0-3 años) en diferentes centros educativos (Món Petit; Muro, Confits; Sa Pobla) y escuelas de verano (Marxeta d´Estiu; Muro, 2013-2015) así como la realización de actividades extraescolares tanto para educación Infantil como primaria. (CEIP Vialfàs, Sa Pobla; Norai, Pto Alcudia; Hort dels Fassers; Alcudia 2015-2016)",
      "Además, he dinamizado como animadora y monitora diferentes actos lúdicos infantiles de carácter privado.",
      "En la actualidad y desde julio de 2016, soy la directora y coordinadora de la Escoleta d´Infants de Muro."
    ]
  },
  {
    name: "Francisca Serra Cabanellas",
    img: franciscaFisio,
    description: [
      "Mi nombre es Xisca Serra Cabanellas, tengo 27 años y mi admiración por la fisioterapia empezó cuando apenas tenía 8 años. Entonces, y tras muchos meses de visitas a médicos, me detectaron la Enfermedad de Perthes. Una enfermedad de la cadera que cursa con una debilidad progresiva de la cabeza del fémur y que puede provocar una deformidad permanente de la misma. Entonces, todo cambió. Tuve que dejar la gimnasia deportiva y cambiar los días de colegio y juego por visitas e ingresos en el hospital. Fue en aquel momento, en el que apareció quien hizo mi recuperación más fácil y entretenida: el fisioterapeuta. Desde entonces, supe que quería ser fisioterapeuta pediátrica, para compartir mi experiencia como paciente y profesional y ayudar así a todos los niños que lo pudieran necesitar y a sus familias.",
      "Finalicé el Grado de Fisioterapia en 2015 en la Universidad Autónoma de Barcelona. Posteriormente, cursé el Máster de Fisioterapia Pediátrica en la Universidad Internacional de Cataluña y el curso de Experto Universitario en Terapias Asistidas con Animales en la Universidad de Las Islas Baleares. Del mismo modo, he realizado cursos formativos presenciales y on-line para profundizar más en el mundo de la pediatría. He trabajado como fisioterapeuta en centros especializados en el desarrollo psicomotor del niño, así como en el ámbito deportivo, siendo la fisioterapeuta de equipos de fútbol de Mallorca.",
      "Actualmente combino el trabajo en el ámbito de la rehabilitación domiciliaria (pediátrica y no pediátrica) con la realización de terapias asistidas con caballos."
    ]
  }
];

export default () => {
  return (
    <Container fluid={true}>
      {team.map((emp, key) =>
        <Person key={key} {...emp} index={key}/>
      )}
    </Container>
  );
}

const Person = ({ name, img, description, index }) => {

  const styleBack = {
    backgroundImage: 'url(' + img + ')',
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center top",
    backgroundSize: "cover",
    minHeight: "85vh",
    position: "sticky",
    top: 115
    // backgroundAttachment: "fixed"
  };


  return (
    <Row>
      <Col xs={{ size: 12, order: 0 }} lg={{ size: 6, order: (index % 2 === 0) ? 0 : 1 }} style={{opacity: 0.7}}>
        <Row style={styleBack} className="justify-content-center">
          <Col xs={11} md={10} className="text-center">
            <Row
              className={"h-100 align-items-end no-gutters text-white " + ((index % 2 === 0) ? "justify-content-lg-end" : "")}>
              <h3>
                {name}
              </h3>
            </Row>
          </Col>
        </Row>
      </Col>
      <Col>
        <Row className="py-4">
          <TitleAndContent styleContent={styles.text} freeChildren>
            {description.map((paragraph, key) =>
              <p key={key} className="text-justify">
                {paragraph}
              </p>
            )}
          </TitleAndContent>
        </Row>
      </Col>
    </Row>
  )
};


const styles = {
  logo: {
    width: "100%"
  },
};

