import React, { useContext, useState } from "react";

import { Container, Row } from 'reactstrap';
import { useHistory } from 'react-router-dom'
import { UserContext } from "../wrappers/UserWrapper";
import { toast } from 'react-toastify';
import ReusableForm from "../components/shared/form/ReusableForm";
import { NavLink } from "react-router-dom";

const queryString = require('query-string');

const inputs = [
  { label: "Email", type: "text", id: "emailLogin", name: "identifier" },
  { label: "Contraseña", type: "password", id: "passwordLogin", name: "password" },
];

export default ({ location }) => {
  const { returnTo } = queryString.parse(location.search);
  let history = useHistory();
  const { login } = useContext(UserContext);
  const [state, setState] = useState({});


  const submit = () => {
    const { identifier, password } = state;
    setState({ buttonDisabled: true });
    login({ identifier, password })
      .then((user) => {
        if (user) {
          history.replace(returnTo ? returnTo : "/");
          toast.success("Bienvenido/a " + (user.customer.name || user.email))
        } else {
          toast.error("Lo sentimos, no existe ningún usuario con ese email y contraseña")
        }
      })
      .catch(() => toast.error("Lo sentimos, no existe ningún usuario con ese email y contraseña"))
      .finally(() => setState({ buttonDisabled: false }));
  };
  return (
    <Container fluid={true} className="py-5">
      <Row className="h-100 justify-content-center align-items-center" style={{ minHeight: "60vh" }}>
        <div style={{ width: "350px", maxWidth: "90vw" }}>
          <ReusableForm submitText="INICIAR SESSIÓN" onSubmit={submit} {...{ state, setState, inputs }}
                        className="mb-3"/>
          <div className="mt-2">
            ¿No tienes cuenta? Registrate
            <NavLink to={"/signIn"} className=" px-2">
              AQUÍ
            </NavLink>
          </div>
        </div>
      </Row>
    </Container>
  )
}
