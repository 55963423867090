import React, { createContext, useEffect, useState } from "react";
import { getServices } from "../api/requests";

export const ServicesContext = createContext();


export default ({ children }) => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    refreshServices()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshServices = () => {
    getServices()
      .then(({ data }) => {
        setServices(data)
      });
  };

  return <ServicesContext.Provider value={{ services }} children={children}/>;
}
