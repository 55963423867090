import React, { createContext, useState } from "react";

export const BookingContext = createContext();


export default ({ children }) => {

  const [booking, setBooking] = useState({
    service: null,
    hoursPack: null,
    catered: [],
    extraCatered: [],
    dates: [],
    address: null,
    location: null,
    postalCode: null
  });


  const changeService = (service) => {
    setBooking((booking) => ({ ...booking, service, hoursPack: null }))
  };

  const changeHoursPack = (hoursPack) => {
    setBooking((booking) => ({ ...booking, hoursPack }))
  };

  const catered = {
    toggle: (theCatered) => {
      const exists = !!booking.catered.filter(cat => cat.id.toString() === theCatered.id.toString())[0];
      let newCatereds = booking.catered;
      if (exists) newCatereds = booking.catered.filter(cat => cat.id.toString() !== theCatered.id.toString());
      else newCatereds = [...booking.catered, theCatered];
      setBooking((booking) => ({ ...booking, catered: newCatereds }))
    },
    exists: (id) => {
      const filter = booking.catered.filter(cat => cat.id.toString() === id.toString())[0]
      return !!filter
    },
    clean: () => {
      setBooking((booking) => ({ ...booking, catered: [] }))
    }
  };
  const extraCatered = {
    toggle: (theCatered) => {
      const exists = !!booking.extraCatered.filter(cat => cat.id.toString() === theCatered.id.toString())[0];
      let newCatereds = booking.extraCatered;
      if (exists) newCatereds = booking.extraCatered.filter(cat => cat.id.toString() !== theCatered.id.toString());
      else newCatereds = [...booking.extraCatered, theCatered];
      setBooking((booking) => ({ ...booking, extraCatered: newCatereds }))
    }
  };
  const dates = {
    toggle: (range) => {
      const exists = !!booking.dates.filter(dat => dat.id.toString() === range.id.toString())[0];
      let newDates = booking.dates;
      if (exists) newDates = booking.dates.filter(dat => dat.id.toString() !== range.id.toString());
      else newDates = [...booking.dates, range];
      setBooking((booking) => ({ ...booking, dates: newDates }))
    }
  };

  const changePlace = ({ address = "", location = "", postalCode = "" }) => {
    setBooking((booking) => ({ ...booking, address, location, postalCode }))
  };


  const actions = {
    changeService,
    changeHoursPack,
    catered,
    extraCatered,
    dates,
    changePlace
  };

  const getBodyMessage = () => ({
    dates: booking.dates.map((range) => ({ init: range.init.format(), end: range.end.format() })),
    catereds: booking.catered.map(cat => cat.id),
    extraCatereds: booking.extraCatered,
    address: booking.address,
    location: booking.location,
    postalCode: booking.postalCode
  });
  return <BookingContext.Provider value={{ booking, actions, getBodyMessage }} children={children}/>;
}
