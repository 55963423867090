import React, { useContext, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import { useLocation } from "react-router";
import { ToastContainer } from 'react-toastify';
import Navbar from "./components/navbar/Navbar";
import Home from "./pages/Home";
import './App.css';
import "./styles/datetime.css"
import UserWrapper, { UserContext } from "./wrappers/UserWrapper";
import Footer from "./components/footer/Footer";
import Login from "./pages/Login";
import SignIn from "./pages/SignIn";
import Contact from "./pages/Contact";
import ServicesWrapper from "./wrappers/ServicesWrapper";
import Service from "./pages/Service";
import BookingWrapper, { BookingContext } from "./wrappers/BookingWrapper";
import Booking from "./pages/Booking";
import ContactEmployee from "./pages/ContactEmployee";
import ClientZone from "./pages/ClientZone";
import Team from "./pages/Team";


const stripePromise = loadStripe('pk_test_51GzOw2DqsKOMNDOCWc1pqbhgWQRRkZYrDKLXZgzn9IzZxklsqvxXUGLx5Dgbgwi6DHt1uw4Jc8sMRXHgJ95U00Sz0011fghWAh');

function App() {

  if(process.env.REACT_APP_HIDDEN){
    return (
      <div>
        En construcción...
      </div>
    )
  }

  return (
    <Elements stripe={stripePromise}>
      <ToastContainer {...{ closeButton: false, position: "bottom-right" }}/>
      <UserWrapper>
        <ServicesWrapper>
          <BookingWrapper>
            <React.Fragment>
              <BrowserRouter>
                <ScrollToTop/>
                <Navbar/>
                <Content/>
                <Footer/>
              </BrowserRouter>
            </React.Fragment>
          </BookingWrapper>
        </ServicesWrapper>
      </UserWrapper>
    </Elements>
  );
}


const Content = () => {
  const { booking } = useContext(BookingContext);
  const { user } = useContext(UserContext);
  const { service } = booking;
  return (
    <Switch>
      <Route exact path="/" component={Home}/>
      <Route exact path="/services" component={Home}/>
      <Route exact path="/team" component={Team}/>
      {!user && <Route exact path="/login" component={Login}/>}
      {!user && <Route exact path="/signIn" component={SignIn}/>}
      <Route exact path="/contact/nanny" component={ContactEmployee}/>
      <Route exact path="/contact" component={Contact}/>
      <Route exact path="/service/:url" component={Service}/>
      {user !== null && <Route exact path="/client-zone/bookings" component={ClientZone}/>}
      {user !== null && <Route exact path="/client-zone/hour-pack/:id/complete" component={ClientZone}/>}
      {user !== null && <Route exact path="/client-zone/catered/new" component={ClientZone}/>}
      {user !== null && <Route exact path="/client-zone/catered/:id/update" component={ClientZone}/>}
      {user !== null && <Route exact path="/client-zone/profile" component={ClientZone}/>}
      {user !== null && <Route exact path="/client-zone/catered" component={ClientZone}/>}
      {user !== null && <Route exact path="/client-zone/profile" component={ClientZone}/>}
      {user !== null && <Route exact path="/client-zone/profile/update" component={ClientZone}/>}
      {user !== null && <Redirect from='/client-zone' to='/client-zone/bookings'/>}
      {service && <Route exact path={"/booking/catered"} component={Booking}/>}
      {service && <Route exact path={"/booking/dates"} component={Booking}/>}
      {service && <Route exact path={"/booking/summary"} component={Booking}/>}
      {service && <Redirect from='/booking' to='/booking/catered'/>}
      <Redirect to={"/"}/>
    </Switch>
  )
};


const ScrollToTop = () => {
  const { pathname } = useLocation();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (pathname !== "/" && pathname !== "/services")
      window.scrollTo(0, 0)
  }, [pathname]);
  return null;
};


export default App;
